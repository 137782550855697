
.filepond--root {
    font-family: 'Inter';
    margin-bottom: 0
}

.filepond--credits {
    display: none;
}

.filepond--file-info-sub{
    display: none;
}

.filepond--panel-root {
    background-color: #fff;
    border: 1px solid var(--gray-300) ;

}

.filepond--drop-label{
    height: 42px !important;
    min-height: 42px !important;
}

.filepond--file-action-button {
    cursor: pointer;
}

.filepond--label-action {
    text-decoration: none;
    border: 1px solid var(--indigo-500) ;
    border-radius: .5rem;
    color: var(--indigo-500);
    padding: 4px 14px;
    font-weight: 600;
    font-size: 16px;

}

.filepond--file-info-main {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filepond--file-action-button {
    background-color: var(--gray-800) !important;
}

[data-filepond-item-state=processing-complete] .filepond--file-action-button {
    background-color: #fff !important;
}

[data-filepond-item-state=processing-complete] .filepond--file-action-button svg {
    color: #000 !important;
}

[data-filepond-item-state*=error] .filepond--file-action-button,
[data-filepond-item-state*=invalid] .filepond--file-action-button {
    background-color: #fff !important
;
}

[data-filepond-item-state*=error] .filepond--file-action-button svg, 
[data-filepond-item-state*=invalid] .filepond--file-action-button svg {
    color: var(--error-500) !important;
}

.filepond--file-action-button.filepond--file-action-button{
    width: 16px;
    height: 16px;
}

.filepond--file-action-button.filepond--file-action-button svg{
    width: 16px;
    height: 16px;
}

.filepond--file .filepond--progress-indicator[data-align*=right] {
    margin-right: 0;
}

.filepond--file .filepond--progress-indicator{
    margin-top: 0;
}

.filepond--process-indicator {
width: 16px;
height: 16px;
}

.filepond--process-indicator svg{
    width: 16px;
    height: 16px;
}

.filepond--file {
    align-items: center;
    /* padding: 8px 1px; */
    height: 30px !important;
}

.filepond--file-info {
    display: block;
}

.filepond--file-info-sub{
    display: none;
}

.filepond--file-status-sub {
    display: none;
}

.filepond--item-panel {
    background-color: var(--gray-300) !important;
}

[data-filepond-item-state=processing-complete] .filepond--item-panel {
    background-color: var(--indigo-500) !important;
}

[data-filepond-item-state*=error] .filepond--item-panel,
[data-filepond-item-state*=invalid] .filepond--item-panel {
    background-color: var(--error-500) !important;
}

.filepond--item {
    margin: 0
}

.filepond--root .filepond--list-scroller {
    margin-top: 0;
}

.filepond--list-scroller {
    top: 8px
}